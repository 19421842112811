<template>
  <Text :text="[
    {
      text: `${status == 0 ? '✔' : '✘'}`,
      style: {
        backgroundColor: status == 0 ? 'var(--green)' : 'var(--red)',
        color: 'var(--black)',
        padding: '0 0.5rem'
      }
    },
    {
      text: '',
      style: {
        color: status == 0 ? 'var(--green)' : 'var(--red)',
        backgroundColor: 'var(--blue)'
      }
    },
    {
      text: cwd,
      style: {
        backgroundColor: 'var(--blue)',
        color: 'var(--brightBlack)',
        padding: '0 0.5rem'
      }
    },
    {
      text: '',
      style: {
        color: 'var(--blue)',
        backgroundColor: 'var(--black)',
        paddingRight: '0.5rem'
      }
    },
  ]"/>
</template>

<script>
import Text from './Text';
export default {
  props: {
    status: {
      required: true,
      type: Number
    },
    cwd: {
      required: true,
      type: String
    }
  },
  computed: {
    statusStyle() {
      return {
        backgroundColor: this.status == 0 ? 'var(--green)' : 'var(--red)',
      }
    }
  },
  components: {
    Text
  }
}
</script>

<style scoped>
.prompt-status {
  color: black;
  padding: 0px 10px;
}
</style>
