<template>
  <span v-for="(chunk, index) in text" :key="index" :style="{...style, ...chunk.style}">
    <a v-if="chunk.isAnchor | chunk.href !== undefined" :href="chunk.href" target="_blank">{{ chunk.text }}</a>
    <img v-else-if="chunk.isImg" :src="chunk.src"/>
    <a v-else-if="chunk.isAnchorImg" :href="chunk.href"><img :src="chunk.src"></a>
    <template v-else>{{ chunk.text }}</template>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: Array
    },
    style: {
      type: Object
    }
  }
}
</script>

<style scoped>
span {
  white-space: pre-wrap;
}
</style>