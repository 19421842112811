import util from './util'

const welcomeText = `
WELCOME to my site!

`

const helpText = `nathanp.me terminal, version 0.0.1-release

The commands are defined internally. Type 'help' to see this list.
Type 'help name' to find out more about function 'name'.
Use 'info terminal' to find out more about this terminal specifically.

`

const aboutText = [{text: `My name is Nathan Pannell and I am a Software Developer in the Bay Area. I am currently working on Peridot at Niantic.
I have a passion for programming and am constantly looking to improve my skillset. I love working
on side projects, doing Game Jams, and spending my free time solving interesting problems.
Check out my resume here: `}, {text: 'Nathan Pannell', isAnchor: true, href: './Nathan Pannell.pdf', style: {color: 'var(--yellow)'}}, {text: `
My github is here: `}, {text: 'GitHub', href: 'https://github.com/natebot13', style: {color: 'var(--yellow)'}}];

// const resumeLink = [{text: 'Nathan Pannell', isAnchor: true, href: './resume.pdf'}];

const commands = {
  help(term) {
    term.print(helpText);
    let keys = Object.keys(commands);
    keys.sort().forEach(key => {
      if (commands[key].help) {
        term.print(`${key}\t\t\t${commands[key].help}`);
      }
    });
    return 0;
  },

  about(term) {
    term.log(aboutText);
    return 0;
  },

  async connect(term) {
    term.print('Logging in to nathanp.me');
    await util.snooze(200);
    term.put('.');
    await util.snooze(200);
    term.put('.');
    await util.snooze(200);
    term.put('.');
    await util.snooze(200);
    term.print('connected!');
    await util.snooze(200);
    term.print(welcomeText);
    await util.snooze(200);
    return 0;
  },

  pwd(term) {
    term.log(term.cwd);
    return 0;
  },

  ls(term) {
    term.log('a bunch of junk');
    return 0;
  },

  cd(term, path='~') {
    term.cwd = path;
    return 0;
  },

  async exit() {
    window.open(location, '_self').close();
    await util.snooze(100);
    return 0;
  },

  info(term) {
    term.print(`I built this terminal from scratch at one point in plain JavaScript. But then I learned about the wonders of frameworks, and rebuilt it in the best one, Vue.js.`)
    return 0;
  }
}

commands.help.help = 'Prints out info about the available commands';
commands.about.help = 'Displays info about me';
commands.connect.help = 'Connects to nathanp.me (all simulated, no real connection is taking place';
commands.exit.help = 'Exit this session';
commands.ls.help = 'List contents of current working directory';
commands.pwd.help = 'Print working directory';
commands.cd.help = 'Change directory';

export default commands;