<template>
<div class="terminal" :style="textStyle" @click="$refs.input.focus()">
  <div class="terminal-line" v-for="(line, index) in lines" :key="index">
    <PS1 v-if="line.prompt" :status="line.status" :cwd="line.cwd"/>
    <Text :text="line.text" :style="line.style"/>
  </div>
  <div class="command-buttons"></div>
  <div class="row prompt-line" ref="promptLine">
    <PS1 :status="status" :cwd="cwd"/>
    <input
      v-model="activeHistory[hIndex]"
      :style="textStyle"
      ref="input"
      type="text"
      autocomplete="off"
      spellcheck="false"
      autocorrect="off"
      autocapitalize="none"
      @keypress.enter="execute"
      @keydown.up.prevent="up"
      @keydown.down.prevent="down">
  </div>
</div>
</template>

<script>
import PS1 from './components/PS1';
import Text from './components/Text';
import slateScheme from './themes/slate.json';
import './assets/DejaVuSansMono.ttf'
import commands from './commands';
import util from './util';
export default {
  name: 'App',
  data() {
    return {
      lines: [],
      history: [],
      activeHistory: [''],
      hIndex: 0,
      cwd: '~',
      status: 0,
    };
  },
  async mounted() {
    this.setColorScheme(slateScheme);
    this.$refs.promptLine.style.visibility = 'hidden';
    await commands.connect(this);
    this.$refs.promptLine.style.visibility = '';
    await this.lmetfy('about');

    this.$refs.input.focus();

  },
  methods: {
    async execute() {
      this.$refs.promptLine.style.visibility = 'hidden';
      const command = this.activeHistory[this.hIndex];

      if (command !== '') {
        this.history.push(command);
        this.activeHistory = [...this.history, ''];
        this.hIndex = this.history.length;

        this.print(command, {prompt: true});
        const [cmd, ...args] = command.split(/\s+/);

        if (cmd in commands) {
          this.status = await commands[cmd](this, ...args);
        } else {
          this.log(`${cmd}: command not found`);
          this.status = 1;
        }
      } else {
        this.print('', {prompt: true});
      }

      this.$refs.promptLine.style.visibility = '';
      this.$refs.input.focus();
    },
    up() {
      if (this.hIndex > 0) {
        this.hIndex -= 1;
      }
    },
    down() {
      if (this.hIndex < this.history.length) {
        this.hIndex += 1;
      }
    },
    put(text='') {
      if (text.constructor === String) {
        text = {text: text};
      }
      if (this.lines.length == 0) {
        this.print(text)
      } else {
        this.lines[this.lines.length - 1].text.push(text);
      }
    },
    print(text='\n', {style, prompt=false} = {}) {
      if (text.constructor === String) {
        text = [{text: text}];
      } else if (text.constructor === Object) {
        text = [text];
      }
      this.lines.push({
        cwd: this.cwd,
        prompt: prompt,
        status: this.status,
        style: style,
        text: text
      });
    },
    log(text) {
      this.print(text)
    },
    warn(text) {
      const style = {color: 'var(--yellow)'};
      this.print(text, {style: style});
    },
    error(text) {
      const style = {color: 'var(--red)'};
      this.print(text, {style: style});
    },
    setColorScheme(colorScheme) {
      for (let key of Object.keys(colorScheme)) {
        const root = document.documentElement;
        root.style.setProperty(`--${key}`, colorScheme[key])
      }
    },
    async lmetfy(command) {
      for (let c of command) {
        await util.snooze(100);
        this.activeHistory[this.hIndex] += c;
      }
      await util.snooze(100);
      this.execute();
    }
  },
  computed: {
    textStyle() {
      return {
        backgroundColor: 'var(--black)',
        color: 'var(--brightBlue)',
        fontFamily: 'DejaVuSansMono',
        fontSize: '1rem'
      }
    }
  },
  components: {
    PS1,
    Text
  }
}
</script>

<style>
@font-face {
  font-family: 'DejaVuSansMono';
  src: url('./assets/DejaVuSansMono.ttf');
}
html, body, #app {
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
}

a {
  color: var(--birghtYellow);
}

::selection {
  background: #e5f6ff;
}
::-moz-selection {
  background: #e5f6ff;
}

.terminal {
  min-height: 100vh;
  /* width: 100%; */
}

.prompt {
  margin-right: 1rem;
}

input {
  border-style: none;
  outline: none;
  flex: 1;
  margin: 0px;
  padding: 0px;
}

.row {
  display: flex;
  flex-direction: row;
}
</style>
